import React from 'react';
import { Cell } from 'react-table';
import { ColumnData, ColumnTypes, ColumnKey, ColumnConfig } from '../../types';
import { OwnerRenderer } from '../OwnerRenderer';
import { TeamRenderer } from '../TeamRenderer';
import { ConfidenceLevelRenderer } from '../ConfidenceLevelRenderer';
import { LastUpdateRenderer } from '../LastUpdateRenderer';
import { ProgressIndicatorRenderer } from '../ProgressIndicatorRenderer';
import { InitiativeStatusRenderer } from '../InitiativeStatusRenderer';
import { TitleRenderer } from '../TitleRenderer';
import { ActionsRenderer } from '../ActionsRenderer';
import { TD } from '../../../Table';

export const checkRecentlyUpdatedInRow = (row): boolean => {
  if (row?.original?.data?.lastUpdated?.recentlyUpdated) {
    return true;
  }

  return row.subRows?.some(checkRecentlyUpdatedInRow) ?? false;
};

export const DynamicCellRenderer = <T extends ColumnKey>(
  props: Cell<ColumnTypes[T]['data']> & {
    columnConfig: ColumnConfig;
    columnConfigs: ColumnConfig[];
    loadingState: 'success' | 'loading';
  }
) => {
  const cell: ColumnData = props.value;
  const { loadingState } = props;

  // TODO this could benefit from better typing.
  //  It works, but typescript can't map the keys to the respective config anymore.
  // construct a dict of all configs keyed by the repsective header type
  const configs = React.useMemo(
    () =>
      props.columnConfigs.reduce(
        (prev, cur) => ({ ...prev, [cur.key]: cur }),
        {}
      ),
    [props.columnConfigs]
  );

  if (!cell) return <TD />;
  switch (cell.entityType) {
    case 'goal_title':
    case 'aligned_goal_title':
    case 'key_result_title':
    case 'initiative_title':
    case 'goal_group_title':
      return (
        <TitleRenderer
          cell={cell}
          getToggleRowExpandedProps={props.row.getToggleRowExpandedProps}
          isExpanded={props.row.isExpanded}
          canExpand={props.row.canExpand}
          config={configs.title}
          loadingState={loadingState}
          rowDepth={props.row.depth}
        />
      );
    case 'owner':
    case 'owner_unassigned':
    case 'owner_team':
      return (
        <OwnerRenderer
          cell={cell}
          config={configs.owner}
          loadingState={loadingState}
        />
      );
    case 'team':
      return (
        <TeamRenderer
          cell={cell}
          config={configs.team}
          loadingState={loadingState}
        />
      );
    case 'goal_progress':
    case 'draft_goal_progress':
    case 'key_result_progress':
    case 'draft_key_result_progress':
      return (
        <ProgressIndicatorRenderer
          cell={cell}
          config={configs.progress}
          loadingState={loadingState}
        />
      );
    case 'initiative_progress':
    case 'draft_initiative_progress':
      return (
        <InitiativeStatusRenderer
          cell={cell}
          config={configs.progress}
          loadingState={loadingState}
        />
      );
    // double case because the Renderer works for both cases (right now)
    case 'goal_confidence_level':
    case 'draft_goal_confidence_level':
    case 'key_result_confidence_level':
    case 'draft_key_result_confidence_level':
    case 'initiative_confidence_level':
      return (
        <ConfidenceLevelRenderer
          cell={cell}
          config={configs.confidenceLevel}
          loadingState={loadingState}
        />
      );
    case 'goal_last_updated':
    case 'draft_goal_last_updated':
    case 'key_result_last_updated':
    case 'draft_key_result_last_updated':
    case 'initiative_last_updated':
    case 'draft_initiative_last_updated':
      return (
        <LastUpdateRenderer
          cell={cell}
          config={configs.lastUpdated}
          loadingState={loadingState}
          hasRecentlyUpdated={checkRecentlyUpdatedInRow(props.row)}
        />
      );
    case 'goal_actions':
    case 'draft_goal_actions':
    case 'key_result_actions':
    case 'draft_key_result_actions':
    case 'initiative_actions':
    case 'conversation_goal_actions':
    case 'conversation_key_result_actions':
    case 'conversation_initiative_actions':
    case 'conversation_aligned_goal_actions':
      return (
        <ActionsRenderer
          cell={cell}
          config={configs.actions}
          loadingState={loadingState}
        />
      );
    default: {
      // this is to make a missing Renderer a type error. see
      // https://www.typescriptlang.org/docs/handbook/2/narrowing.html#exhaustiveness-checking
      // for how this works.
      const _exhaustiveCheck: never = cell;
      return _exhaustiveCheck;
    }
  }
};
